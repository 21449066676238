<template>
  <div class="download-dialog-view">
    <NuxtImg
      class="img"
      :src="downloadTarget?.source"
    />
    <p class="warning-text">
      この画像を削除すると元に戻せません。本当に削除しますか？
    </p>
    <div class="foot">
      <v-btn
        class="cancel-btn"
        text="キャンセル"
        @click="handleCloseDialog"
      />
      <v-btn
        class="delete-btn"
        text="削除"
        @click="handleDelete"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['close'])
const { setPopup } = usePopup()
const { $customFetch } = useNuxtApp()
const artifactStore = useArtifactStore()
const downloadTarget = computed(() => artifactStore.displayedArtifact)

const imgId = downloadTarget.value?.id as number

const handleCloseDialog = () => {
  emit('close')
}

const handleDelete = async () => {
  try {
    artifactStore.filterArtifactsById(imgId)
    await $customFetch(`api/artifacts/${imgId}`, {
      method: 'PATCH',
    })

    emit('close')
    artifactStore.displayedArtifact = undefined
    return setPopup('削除に成功しました。', 'primary-light')
  } catch (error) {
    console.error('Error deleting artifact:', error)
    return setPopup('削除に失敗しました。', 'red')
  }
}
</script>

<style lang="scss" scoped>
.download-dialog-view {
  width: 100%;
  max-width: 500px;
  padding: 1.5rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  background-color: rgb(var(--v-theme-primary));
  overflow-y: scroll;

  @include media('sm') {
    max-height: 700px;
  }

  .img {
    width: 100%;
    margin: 0 auto;
    object-fit: contain;
  }

  .warning-text {
    margin: 1rem 0;
    color: red;
    text-align: center;
    font-size: 0.875rem;
  }

  .foot {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;

    .delete-btn,
    .cancel-btn {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border: none;
      cursor: pointer;
    }

    .delete-btn {
      background-color: red;
      color: white;
      margin-left: 1rem;
    }

    .cancel-btn {
      background-color: grey;
      color: white;
      margin-right: 1rem;
    }
  }
}
</style>
